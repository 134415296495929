"use client"
import { useTranslations } from "next-intl"
import Link from "next/link";

function ErrorPage() {
  const t = useTranslations();
  return <main className="grid items-center justify-center w-full text-center p-main mt-16">
    <h1 className="text-outline-gold font-roboto text-[8pc] sm:text-[10pc] xl:text-[15pc] font-extrabold">500</h1>
    <div className="space-y-12 my-12 w-fit px-4">
      <h2 className="font-bold sm:text-[2pc]">{t('error-page-500.title')}</h2>
      <section className="w-full flex items-center justify-center">
        <p className="sm:w-1/2 text-center">{t('error-page-500.description')}</p>
      </section>
    </div>
    <Link href={"/home"} className="link-btn-gold w-fit m-auto">{t('back-to-homepage')}</Link>
  </main>
}

export default ErrorPage